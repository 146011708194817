// Alerts
export const ALERT = {
    SET: 'SET_ALERT', 
    REMOVE: 'REMOVE_ALERT'
};

// Breadcrumb
export const BREADCRUMB = {
    EDIT: 'EDIT_BREADCRUMB'
};

// Loader
export const LOADER = {
    SHOW: 'SHOW_LOADER',
    HIDE: 'HIDE_LOADER'

};

// Auth
export const AUTH = {
    LOGIN: 'LOGIN',
    LOGIN_ERROR: 'LOGIN_ERROR',
    GET_USER: 'GET_USER',
    GET_USER_ERROR: 'GET_USER_ERROR',
    LOGOUT: 'LOGOUT'
};

// Dashboard
export const DASHBOARD = {
    GET: 'GET_DASHBOARD'
};

// Companies
export const COMPANY = {
    FIND: 'FIND_COMPANIES', 
    ADD: 'ADD_COMPANY', 
    DETAILS: 'DETAILS_COMPANY', 
    EDIT: 'EDIT_COMPANY', 
    STATUS: 'STATUS_COMPANY', 
    DELETE: 'DELETE_COMPANY', 
    USER: {
        FIND: 'FIND_USERS_COMPANY', 
        ADD: 'ADD_USER_COMPANY', 
        DETAILS: 'DETAILS_USER_COMPANY', 
        EDIT: 'EDIT_USER_COMPANY', 
        DELETE: 'DELETE_USER_COMPANY'
    }, 
    USER_TYPE: {
        FIND: 'FIND_USER_TYPES_COMPANY'
    }
};

// Users
export const USER = {
    FIND: 'FIND_USERS', 
    TYPES: 'TYPES_USERS', 
    ADD: 'ADD_USER', 
    DETAILS: 'DETAILS_USER', 
    EDIT: 'EDIT_USER', 
    DELETE: 'DELETE_USER'
};

// Memberships
export const MEMBERSHIP = {
    FIND: 'FIND_MEMBERSHIPS', 
    ADD: 'ADD_MEMBERSHIP', 
    DETAILS: 'DETAILS_MEMBERSHIP', 
    EDIT: 'EDIT_MEMBERSHIP', 
    DELETE: 'DELETE_MEMBERSHIP'
};

// Configuration
export const CONFIGURATION_PROFILE = {
    EDIT: 'EDIT_PROFILE'
};