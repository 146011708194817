
import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const Loader = () => {
    return (
        <div className="loader_container">
            <div className="text-center">
                <img className="d-inline-block my-4" alt="logo" width="75" src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/img/logo.png'}/>
                <div className="animation_container">
                    <ThreeDots 
                        height="70" 
                        width="70" 
                        radius="9"
                        color="#4154f0" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ display: 'block' }}
                        visible={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Loader;
