import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Components
import Layout from './components/layout/Layout';
import PrivateRoute from './components/routing/PrivateRoute';
import ValidateSession from './components/routing/ValidateSession';
import NoMatch from './components/routing/NoMatch';

// Login
import Login from './views/auth/Login';

// Dashboard
import Dashboard from './views/dashboard/Dashboard';

// Users
import Users from './views/users/Users';
import AddUser from './views/users/AddUser';
import EditUser from './views/users/EditUser';

// Companies
import Companies from './views/companies/Companies';
import AddCompany from './views/companies/AddCompany';
import EditCompany from './views/companies/EditCompany';
import UsersCompany from './views/companies/users/Users';
import AddUserCompany from './views/companies/users/AddUser';
import EditUserCompany from './views/companies/users/EditUser';

// Memberships
import Memberships from './views/memberships/Memberships';
import AddMembership from './views/memberships/AddMembership';
import EditMembership from './views/memberships/EditMembership';

// Configuración
import Profile from './views/configuration/profile/Construction';

// Redux
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

function App() {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	useEffect(() => {
		store.dispatch(loadUser());
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Layout>								
						<Routes>
							{/* Login */}
							<Route exact path="/login" element={<ValidateSession><Login /></ValidateSession>}/>

							{/* Home */}
							<Route path="/" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>
							<Route path="/home" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>

							{/* Users */}
							<Route path="/users" element={<PrivateRoute routePermission='users.view'><Users /></PrivateRoute>}/>
							<Route path="/users/add" element={<PrivateRoute routePermission='users.add'><AddUser /></PrivateRoute>}/>
							<Route path="/users/edit/:id" element={<PrivateRoute routePermission='users.edit'><EditUser /></PrivateRoute>}/>

							{/* Companies */}
							<Route path="/companies" element={<PrivateRoute routePermission='companies.view'><Companies /></PrivateRoute>}/>
							<Route path="/companies/add" element={<PrivateRoute routePermission='companies.add'><AddCompany /></PrivateRoute>}/>
							<Route path="/companies/edit/:id" element={<PrivateRoute routePermission='companies.edit'><EditCompany /></PrivateRoute>}/>
							<Route path="/companies/users/view/:id" element={<PrivateRoute routePermission='companies.users.view'><UsersCompany /></PrivateRoute>}/>
							<Route path="/companies/users/add/:id" element={<PrivateRoute routePermission='companies.users.add'><AddUserCompany /></PrivateRoute>}/>
							<Route path="/companies/users/edit/:id" element={<PrivateRoute routePermission='companies.users.edit'><EditUserCompany /></PrivateRoute>}/>

							{/* Memberships */}
							<Route path="/memberships" element={<PrivateRoute routePermission='memberships.view'><Memberships /></PrivateRoute>}/>
							<Route path="/memberships/add" element={<PrivateRoute routePermission='memberships.add'><AddMembership /></PrivateRoute>}/>
							<Route path="/memberships/edit/:id" element={<PrivateRoute routePermission='memberships.edit'><EditMembership /></PrivateRoute>}/>

							{/* Configuration */}
							<Route path="/configuration" element={<PrivateRoute routePermission='configuration.profile.view'><Profile /></PrivateRoute>}/>
							
							{/* Not found anything*/}
							<Route path="*" element={<NoMatch />}/>
						</Routes>
					</Layout>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;