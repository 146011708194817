import { DASHBOARD } from '../actions/constants'

const initialState = {
    details: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {

        case DASHBOARD.GET:
            return {
                ...state,
                details: payload
            };

        default:
            return state;
    }

}