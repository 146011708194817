import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatNumber, sortTableNumber } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { findUsers } from '../../actions/users';

import DeleteUser from './DeleteUser';

const Users = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Users',
            path: '/users'
        }
    ];

    const dispatch =  useDispatch();

    const { user } = useSelector(state => state.auth);
    const { list } = useSelector(state => state.users);

    const [users, setUsers] = useState([]);

    const [userDelete, setUserDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Name',
            sortable: true, 
            selector: row => row.name
        },
        {
            name: 'Lastname',
            sortable: true, 
            selector: row => row.lastname
        },
        {
            name: 'Type',
            sortable: true, 
            selector: row => {
                switch(row.type){
                    case 0:
                        return(
                            'Administrator'
                        )
                    case 1:
                        return(
                            'Asistant'
                        )
                    default:
                        break;
                }
            }
        },
        {
            name: 'Username',
            sortable: true, 
            selector: row => row.username || 'Not defined'
        },
        {
            name: 'Email',
            sortable: true, 
            selector: row => row.email || 'Not defined',
            grow: 2
        },        
        {
            name: 'Permissions',
            sortable: true, 
            sortFunction: (a, b) => sortTableNumber(a, b, 'companies'),
            selector: row => row.permissions + '/' + row.total_permissions
        }, 
        {
            name: '',
            cell: row => {
                if(row._id != user._id) {
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                            <Link className="btn btn-sm btn-link px-2 float-end" to={`/users/edit/${row._id}`}><i className="bi bi-pencil-fill text-primary"></i></Link>
                        </div>
                    );
                }else{
                    return null;
                }
            }
        }
    ];

    /* Delete */
    const handleDelete = (user) => {
        setUserDelete(user);
    }

    useEffect(() => {
        dispatch(findUsers());
    }, []);

    useEffect(() => {
        setUsers(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>

            <DeleteUser user={userDelete} restablishUser={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <Link to="/users/add">
                            <button className="btn btn-primary">Add user</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={users}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Users;