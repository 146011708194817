import { MEMBERSHIP } from "../actions/constants";

const initialState = {
    list: [],
    details: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case MEMBERSHIP.FIND:
            return {
                ...state,
                list: payload
            };

        case MEMBERSHIP.DETAILS:
            return {
                ...state,
                details: payload
            };

        case MEMBERSHIP.DELETE:
            return {
                ...state,
                list: state.list.filter( membership => membership._id !== payload.id )
            };

        default:
            return state;
    }
}