import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';

import { formatNumber } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { addCompany } from '../../actions/companies';
import { findMemberships } from '../../actions/memberships';

import { CustomValueContainer } from '../../components/react_select/ReactSelect';

const AddCompany = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Companies',
            path: '/companies'
        },
        {
            activo: true,
            name: 'Add company',
            path: '/companies/add'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { list: list_memberships } = useSelector(state => state.memberships);

    const [companyData, setCompanyData] = useState({
        name: null, 
        membership: null, 
        phone: null,
        email: null,
        address: null
    });

    const [membershipData, setMembershipData] = useState(null);
    const [membershipsFilter, setMembershipsFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Nuevo evento */
    const handleInputChange = ({ target }) => {
        setCompanyData({
            ...companyData,
            [target.name]: target.value
        });
    }

    const handleSelectMembership = (option) => {
        setMembershipData(option);

        setCompanyData({
            ...companyData,
            membership: option != null ? option.value : null
        });
    }

    const handleSave = async () => {
        if(companyData.name && companyData.membership){
            await dispatch(addCompany(companyData));

            await navigate("/companies");
        }else{
            await dispatch(setAlert('Name and membership are required', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(findMemberships());
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_memberships.length; i++) {
            options.push({ value: list_memberships[i]._id, label: list_memberships[i].name + ' (' + formatNumber(list_memberships[i].price, 2, '$ ', '') + ')' });
        }

        setMembershipsFilter(options);
    }, [list_memberships]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);    

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 py-4">
                    <div className="row">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Name" id="name" name="name" value={companyData.name} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="name">Name</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="membership"
                                    name="membership"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Membership"
                                    options={membershipsFilter}
                                    value={membershipData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectMembership(value)}
                                    menuPortalTarget={document.body}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Phone" id="phone" name="phone" value={companyData.phone} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="phone">Phone</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={companyData.email} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="email">Email</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Address" id="address" name="address" value={companyData.address} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="address">Address</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleSave}>Save</button>
                            <Link to='/companies'><button className="btn btn-danger float-end me-2">Cancel</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddCompany;