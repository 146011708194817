import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { sortTableNumber } from '../../../utils/generalFunctions';

import { editBreadcrumb } from '../../../actions/breadcrumb';
import { getCompany, findUsers } from '../../../actions/companies';

import DeleteUser from './DeleteUser';

const Users = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Companies',
            path: '/companies'
        },
        {
            activo: true,
            name: 'Account users',
            path: '/companies/users/view/'
        }
    ];

    const dispatch =  useDispatch();

    const { details, list_users } = useSelector(state => state.companies);

    const { id } = useParams();

    const [users, setUsers] = useState([]);

    const [userDelete, setUserDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Name',
            sortable: true, 
            selector: row => row.name
        },
        {
            name: 'Lastname',
            sortable: true, 
            selector: row => row.lastname
        },
        {
            name: 'Type',
            sortable: true, 
            selector: row => row.type?.name || 'Not defined'
        },
        {
            name: 'Username',
            sortable: true, 
            selector: row => row.username || 'Not defined'
        },
        {
            name: 'Email',
            sortable: true, 
            selector: row => row.email || 'Not defined',
            grow: 2
        },        
        {
            name: 'Permissions',
            sortable: true, 
            sortFunction: (a, b) => sortTableNumber(a, b, 'companies'),
            selector: row => row.total_permissions ? row.permissions + '/' + row.total_permissions : 'Not defined'
        }, 
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/companies/users/edit/${row._id}`}><i className="bi bi-pencil-fill text-primary"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Delete */
    const handleDelete = (user) => {
        setUserDelete(user);
    }

    useEffect(() => {
        dispatch(getCompany(id));
        dispatch(findUsers(id));

        if(id){
            breadcrumbRoutes[1].path = breadcrumbRoutes[1].path + id;

            dispatch(editBreadcrumb(breadcrumbRoutes));
        }
    }, [id]);

    useEffect(() => {
        if(details){
            breadcrumbRoutes[1].name = breadcrumbRoutes[1].name + ' of ' + details.name;

            dispatch(editBreadcrumb(breadcrumbRoutes));
        }
    }, [details]);

    useEffect(() => {
        setUsers(list_users);
    }, [list_users]);

    return (
        <Fragment>

            <DeleteUser user={userDelete} restablishUser={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <Link to={'/companies/users/add/' + id}>
                            <button className="btn btn-primary">Add user</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={users}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Users;