import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {

	const location = useLocation();

    const { permissions } = useSelector(state => state.auth.user);

    return (
        <Fragment>
            <aside id="sidebar" className="sidebar">
				<ul className="sidebar-nav" id="sidebar-nav">
					<li className="nav-item">
						<Link className={'nav-link' + (location.pathname === '/' || location.pathname === '/home' ? '' : ' collapsed')} to="/home">
							<i className="bi bi-grid"></i>
							<span>Home</span>
						</Link>
					</li>

					{
						permissions?.companies?.view &&
							<li className="nav-item">
								<Link className={'nav-link' + (location.pathname === '/companies' || location.pathname.substr(0, 10) === '/companies' ? '' : ' collapsed')} to="/companies">
									<i className="bi bi-building"></i>
									<span>Companies</span>
								</Link>
							</li>
					}

					{
						permissions?.users?.view &&
							<li className="nav-item">
								<Link className={'nav-link' + (location.pathname === '/users' || location.pathname.substr(0, 6) === '/users' ? '' : ' collapsed')} to="/users">
									<i className="bi bi-people"></i>
									<span>Users</span>
								</Link>
							</li>
					}

					{
						permissions?.memberships?.view &&
							<li className="nav-item">
								<Link className={'nav-link' + (location.pathname === '/memberships' || location.pathname.substr(0, 12) === '/memberships' ? '' : ' collapsed')} to="/memberships">
									<i className="bi bi-card-checklist"></i>
									<span>Memberships</span>
								</Link>
							</li>
					}
				</ul>
			</aside>
        </Fragment>
    )
}

export default Sidebar;