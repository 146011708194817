export const formatNumber = (number, decimales, prefix, sufix) => {
    let numberFormated = (Math.round(number * 100) / 100).toFixed(decimales);
    const decimalsFormated = numberFormated.toLocaleString(undefined, { maximumFractionDigits: decimales });
    const finalFormated = prefix + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + sufix;
    return finalFormated;    
}

export const sortTableNumber = (primera, segunda, campo) => {
    if(!isNaN(primera[campo])){
        let a = Number(primera[campo]);
        let b = Number(segunda[campo]);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableString = (primera, segunda) => {
    let a = String(primera.canjeado);
    let b = String(segunda.canjeado);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

export const sortTableDate = (primera, segunda) => {
    let a = new Date(primera.inicio);
    let b = new Date(segunda.inicio);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}