import { USER } from "../actions/constants";

const initialState = {
    list: [],
    details: null, 
    list_types: []
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case USER.FIND:
            return {
                ...state,
                list: payload
            };

        case USER.TYPES:
            return {
                ...state,
                list_types: payload
            };

        case USER.DETAILS:
            return {
                ...state,
                details: payload
            };

        case USER.DELETE:
            return {
                ...state,
                list: state.list.filter( user => user._id !== payload.id )
            };

        default:
            return state;
    }
}