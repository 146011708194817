import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Select from 'react-select';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { findTypes, editUser, getUser, clearUser } from '../../actions/users';

import { CustomValueContainer } from '../../components/react_select/ReactSelect';

const EditUser = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Users',
            path: '/users'
        },
        {
            activo: true,
            name: 'Edit user',
            path: '/users/edit/'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();
    
    const { id } = useParams();
    
    const { list_types } = useSelector(state => state.users);
    const { details } = useSelector(state => state.users);

    const [userData, setUserData] = useState({
        id: null, 
        name: null, 
        lastname: null, 
        type: null,
        email: null,
        username: null,
        password: null
    });

    const [typeData, setTypeData] = useState(null);
    const [typesFilter, setTypesFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Edit user */
    const handleInputChange = ({ target }) => {
        setUserData({
            ...userData,
            [target.name]: target.value
        });
    }

    const handleSelectType = (option) => {
        setTypeData(option);

        setUserData({
            ...userData,
            type: option != null ? option.value : null
        });
    }

    const handleSave = async () => {
        if(userData.name && userData.type != null && userData.username){
            await dispatch(editUser(userData));

            await navigate("/users");
        }else{
            await dispatch(setAlert('All fields are required', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(clearUser());
        dispatch(findTypes());

        return () => {
            dispatch(clearUser());
        }
    }, []);

    useEffect(() => {
        dispatch(getUser(id));

        if(id){
            breadcrumbRoutes[1].path = breadcrumbRoutes[1].path + id;

            dispatch(editBreadcrumb(breadcrumbRoutes));
        }
    }, [id]);

    useEffect(() => {
        if(details){
            setUserData({
                ...userData,
                id: details?._id,
                name: details?.name,
                lastname: details?.lastname,
                type: details?.type,
                email: details?.email,
                username: details?.username
            });

            if(typesFilter.length > 0){
                let user_type_assigned = typesFilter.find( type => String(type.value) === String(details?.type) );
    
                if(user_type_assigned){
                    setTypeData({value: user_type_assigned.value, label: user_type_assigned.label});
                }
            }
        }
    }, [details, typesFilter]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_types.length; i++) {
            options.push({ value: list_types[i].id, label: list_types[i].name, permissions: list_types[i].permissions });
        }

        setTypesFilter(options);
    }, [list_types]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);    

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 py-4">
                    <div className="row">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Name" id="name" name="name" value={userData.name} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="name">Name</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Lastname" id="lastname" name="lastname" value={userData.lastname} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="lastname">Lastname</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="type"
                                    name="type"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Type"
                                    options={typesFilter}
                                    value={typeData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectType(value)}
                                    menuPortalTarget={document.body}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={userData.email} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="email">Email</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Username" id="username" name="username" value={userData.username} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="username">Username</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Password" id="password" name="password" value={userData.password} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="password">Password</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleSave}>Save</button>
                            <Link to='/users'><button className="btn btn-danger float-end me-2">Cancel</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditUser;