import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Breadcrumb = () => {

    const sections = useSelector(state => state.breadcrumb);
    
    return (
        (sections.length > 0)
            &&
                <div className="pagetitle">
                    <h1>{ sections.find( section => section.activo === true )?.name || '' }</h1>
                    <nav>
                    <ol className="breadcrumb">
                        {
                            sections.map(section => {
                                return(
                                    (section.activo)
                                        ?
                                            <li className="breadcrumb-item active" key={section.name}>{section.name}</li>
                                        :
                                            <li className="breadcrumb-item" key={section.name}><Link to={section.path}>{section.name}</Link></li>
                                )
                            })
                        }
                    </ol>
                    </nav>
                </div>
    )
}

export default Breadcrumb;