import { ALERT } from '../actions/constants'

const initialState = [];

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case ALERT.SET:
            return [...state, payload];
            
        case ALERT.REMOVE:
            return state.filter( alert => alert.id !== payload.id );
            
        default:
            return state;
    }
}