import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { statusCompany } from '../../actions/companies';

const StatusCompany = ({ company, restablishCompany }) => {

    const dispatch =  useDispatch();

    const [statusModal, setStatusModal] = useState(false);
    const [statusProcess, setStatusProcess] = useState(false);

    /* Status company */
    const handleProcess = async (status) => {
        await setStatusProcess(true);
        await dispatch(statusCompany(company?._id, status));
        await handleClose(null);
        await setStatusProcess(false);
    }

    const handleClose = () => {
        if(!statusProcess){
            restablishCompany(null);
            setStatusModal(false);
        }
    }

    useEffect(() => {
        if(company != null){
            setStatusModal(true);
        }
    }, [company]);

    return (
        <Modal show={statusModal} backdrop="static" centered onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Status company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you like to change the status of the company <b>{company?.name}</b>?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary mr-3" onClick={() => handleClose()} disabled={ statusProcess }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcess ?
                        <button type="button" className={'btn btn-' + (company?.active ? 'danger' : (company?.balance > 0 ? 'warning' : 'success'))} disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {company?.active ? 'Inactive' : (company?.balance > 0 ? 'Active with debt' : 'Active')}</button>
                    :
                        <button type="button" className={'btn btn-' + (company?.active ? 'danger' : (company?.balance > 0 ? 'warning' : 'success'))} onClick={() => handleProcess(company?.active ? false : true)}><i className="fas fa-check fa-sm"></i> {company?.active ? 'Inactive' : (company?.balance > 0 ? 'Active with debt' : 'Active')}</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default StatusCompany;