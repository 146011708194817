import { COMPANY } from "../actions/constants";

const initialState = {
    list: [],
    details: null, 
    list_users: [], 
    details_user: null, 
    list_types: []
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case COMPANY.FIND:
            return {
                ...state,
                list: payload
            };

        case COMPANY.DETAILS:
            return {
                ...state,
                details: payload
            };

        case COMPANY.STATUS:
            return {
                ...state,
                list: state.list.map(company => company._id === payload._id ? payload : company)
            };

        case COMPANY.DELETE:
            return {
                ...state,
                list: state.list.filter( company => company._id !== payload.id )
            };

        case COMPANY.USER.FIND:
            return {
                ...state,
                list_users: payload
            };

        case COMPANY.USER.DETAILS:
            return {
                ...state,
                details_user: payload
            };

        case COMPANY.USER.DELETE:
            return {
                ...state,
                list_users: state.list_users.filter( user => user._id !== payload.id )
            };

        case COMPANY.USER_TYPE.FIND:
            return {
                ...state,
                list_types: payload
            };

        default:
            return state;
    }
}