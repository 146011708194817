import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { formatNumber, sortTableNumber } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { findCompanies } from '../../actions/companies';

import StatusCompany from './StatusCompany';
import DeleteCompany from './DeleteCompany';

const Companies = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Companies',
            path: '/companies'
        }
    ];

    const dispatch =  useDispatch();

    const { list } = useSelector(state => state.companies);

    const [companies, setCompanies] = useState([]);

    const [companyStatus, setCompanyStatus] = useState(null);
    const [companyDelete, setCompanyDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Name',
            sortable: true, 
            selector: row => row.name
        },
        {
            name: 'Membership',
            sortable: true, 
            selector: row => row.membership
        },
        {
            name: 'User accounts',
            sortable: true, 
            selector: row => row.users
        }, 
        {
            name: 'Created',
            cell: item => item.created ? new Date(item.created).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not defined'
        },
        {
            name: 'Status',
            selector: row => {
                if(row.active){
                    return(
                        <h5><button type="button" className="btn btn-lg" onClick={() => handleStatus(row)}><Badge pill bg={row.balance > 0 ? 'warning' : 'success'}>{row.balance > 0 ? 'Active with debt' : 'Active'}</Badge></button></h5>
                    )
                }else{
                    return(
                        <h5><button type="button" className="btn btn-lg" onClick={() => handleStatus(row)}><Badge pill bg="danger">Inactive</Badge></button></h5>
                    )
                }
            }
        },
        {
            name: 'Balance',
            sortable: true, 
            sortFunction: (a, b) => sortTableNumber(a, b, 'balance'),
            selector: row => row.balance != null ? formatNumber(row.balance, 2, '$ ', '') : 'Not defined'
        }, 
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/companies/edit/${row._id}`}><i className="bi bi-pencil-fill text-primary"></i></Link>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/companies/users/view/${row._id}`}><i className="bi bi-people-fill text-success"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Status */
    const handleStatus = (company) => {
        setCompanyStatus(company);
    }

    /* Delete */
    const handleDelete = (company) => {
        setCompanyDelete(company);
    }

    useEffect(() => {
        dispatch(findCompanies({
            name: null,
            status: null, 
            fecha_inicio: null,
            fecha_fin: null
        }));
    }, []);

    useEffect(() => {
        setCompanies(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>
            <StatusCompany company={companyStatus} restablishCompany={handleStatus} />
            <DeleteCompany company={companyDelete} restablishCompany={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <Link to="/companies/add">
                            <button className="btn btn-primary">Add company</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={companies}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Companies;