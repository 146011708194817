import {v4 as uuid} from 'uuid';

import { ALERT } from './constants'

export const setAlert = (msg, alertType, timeout = 5000) => dispatch => {
    const id = uuid();

    dispatch({
        type: ALERT.SET,
        payload: {
            msg, alertType, id
        }
    });

    setTimeout(() => {
        dispatch({
            type: ALERT.REMOVE,
            payload: {
                id
            }
        });
    }, timeout);
}

export const removeAlert = (id) => dispatch => {
    dispatch({
        type: ALERT.REMOVE,
        payload: {
            id
        }
    });
}