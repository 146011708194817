import { combineReducers } from 'redux';

import alert from './alert';
import loader from './loader';
import breadcrumb from './breadcrumb';

import auth from './auth';
import dashboard from './dashboard';
import companies from './companies';
import users from './users';
import memberships from './memberships';
import configuration from './configuration';

export default combineReducers({
    alert, loader, breadcrumb, auth, dashboard, companies, users, memberships, configuration
});