import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { editBreadcrumb } from '../../../actions/breadcrumb';

const Construction = () => {
    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Profile',
            path: '/configuration'
        }
    ];

    const dispatch = useDispatch();

    const styles = {
        container: {
            textAlign: 'center',
            padding: '50px',
        },
        icon: {
            fontSize: '4rem',
            color: '#6c757d',
        },
        title: {
            fontSize: '2rem',
            marginTop: '20px',
        },
        text: {
            fontSize: '1.2rem',
            color: '#6c757d',
        },
    };

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <div style={styles.container}>
            <i className="bi bi-tools" style={styles.icon}></i>
            <h1 style={styles.title}>Under Construction!</h1>
            <p style={styles.text}>This section will be available soon.</p>
            <p style={styles.text}>Thank you for your patience.</p>
        </div>
    );
};

export default Construction;