
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeAlert } from '../../actions/alert';

const alertStyle = (index) => ({
    fontSize: '16px',
    position: 'fixed',
    right: '20px',
    top: `${80 + index * 65}px`,
    zIndex: 5000
});

const Notification = () => {

    const dispatch = useDispatch();

    const alerts = useSelector(state => state.alert);

    const handleCloseSnackbar = (id) => {
        dispatch(removeAlert(id));
    }

    return (
        <Fragment>
            {
                alerts !== null && alerts.length > 0 && alerts.map((alert, index) => (
                    <div key={alert.id} style={alertStyle(index)}>
                        <div className={'alert alert-' + alert.alertType + ' alert-dismissible fade show'} role="alert">
                            {alert.msg}
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => handleCloseSnackbar(alert.id)}></button>
                        </div>
                    </div>
                ))
            }
        </Fragment>
    );
}

export default Notification;
